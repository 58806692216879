.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html { background: transparent }

body {
  padding: 0;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 1200px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.red {
  border: solid 1px red !important;
}

.red:focus {
    outline: none !important;
    border:1px solid red !important;
    box-shadow: 0 0 5px red !important;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.angucomplete-dropdown {
    overflow-y: auto;
    max-height: 200px;
}

.borderless td, .borderless th {
    border: none !important;
}

div.large-modal > div.modal-dialog {
  width: 40% !important;
  overflow-y: auto;
  max-height: 70%;
}

.btn:focus {
  outline: none !important;
}

:focus {outline:0 !important;}

.grid .ui-grid-header-cell {
  background-color: lightgray !important;
}

.grid {
  height: 1530px;
  background-color: lightgray;
}

.button-padding-top {
  padding-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.selectpicker-long {
  min-width: 300px;
}

.btn-group-wrap {
  white-space: pre-wrap !important;
  overflow: hidden;
}

.uib-datepicker-popup .btn-default {
  background-color: white !important;
  color: black !important;
}

@media (max-width: 767px){
  h1, .h1 {
    font-size: 27px !important;
  }
  .selectpicker-long {
    min-width: 150px;
  }
}